import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';


@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(private afAuth: AngularFireAuth) { }

    login(username: string, password: string): Promise<any> {
        return new Promise((success, reject) => {
            // this.afAuth.auth.signInWithEmailAndPassword(username, password).then(userData => success(userData), err => reject(err));
            this.afAuth.signInWithEmailAndPassword(username, password).then(userData => success(userData), err => reject(err));

        });
    }

    logout(): Promise<any> {
        // return this.afAuth.auth.signOut();
        return this.afAuth.signOut();

    }

    getAuth() {
        return this.afAuth.authState;
    }

}
