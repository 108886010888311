import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// // Add Font Awesome Module
// import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
// import { library } from '@fortawesome/fontawesome-svg-core';
// import { far } from '@fortawesome/free-regular-svg-icons';
// library.add(far);

import { AppMainComponent } from './components/app-main/app-main.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';

@NgModule({
  declarations: [NavbarComponent, AppMainComponent, FooterComponent],
  imports: [CommonModule, RouterModule],
  exports: [
    ReactiveFormsModule,
    AppMainComponent,
    NavbarComponent,
    FooterComponent
  ]
})
export class SharedModule {}
