import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-main',
  templateUrl: './app-main.component.html',
  styleUrls: ['./app-main.component.css']
})
export class AppMainComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
