import { Component, OnInit } from '@angular/core';
import { Upload } from '../../../models/upload';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.css']
})
export class UploaderComponent implements OnInit {
  files: File[] = [];
  uploads: Upload[];
  isHovering: boolean;

  constructor(private title: Title) { }

  ngOnInit() {
    this.title.setTitle('File Transfer | Upload');
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  onDrop(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      this.files.push(files.item(i));
    }
  }

  onComplete(fileToDelete: File) {
    setTimeout(() => {
      this.files.forEach((item, index) => {
        if (item.name === fileToDelete.name) {
          this.files.splice(index, 1);
        }
      });
    }, 3000);
  }
}
