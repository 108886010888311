import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';
import { firestore } from 'firebase';
import { Upload } from '../models/upload';

@Injectable({
  providedIn: 'root'
})
export class FileDataService {

  userId: string;
  filesCollection: AngularFirestoreCollection<Upload>;


  constructor(
    private afs: AngularFirestore,
    private auth: AuthService) {
      this.auth.getAuth().subscribe(user => {
        this.userId = user.uid;
        this.filesCollection = this.afs.collection('files', ref => ref.where('userId', '==', user.uid).where('delete', '==', false));
      }
      );
  }

  addFile(file: Upload) {
    const id = this.afs.createId();
    const today: Date = new Date();
    const expiryDate = new Date();

    expiryDate.setDate(today.getDate() + file.expiryDays);

    file.userId = this.userId;
    file.id = id;
    file.uploadDate = firestore.Timestamp.fromDate(today);
    file.expiryDate = firestore.Timestamp.fromDate(expiryDate);
    this.filesCollection.doc<Upload>(id).set(file);
  }

  deleteFile(upload: Upload) {
    const doc: AngularFirestoreDocument<Upload> = this.afs.doc(`files/${upload.id}`);
    doc.update(upload);
  }

  getFiles(): Observable<Upload[]> {

    return this.filesCollection.valueChanges();
  }

}
