import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DropzoneDirective } from './directives/dropzone.directive';
import { UploaderComponent } from './components/uploader/uploader.component';
import { UploadTaskComponent } from './components/upload-task/upload-task.component';
import { DownloaderComponent } from './components/downloader/downloader.component';
import { DownloadFileComponent } from './components/download-file/download-file.component';

import { TransferRoutingModule } from './transfer-routing.module';

// Add Font Awesome Module
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(far);

@NgModule({
  declarations: [
    DropzoneDirective,
    UploaderComponent,
    UploadTaskComponent,
    DownloaderComponent,
    DownloadFileComponent
  ],
  imports: [
    CommonModule,
    TransferRoutingModule,
    FontAwesomeModule
  ],
  exports: [
    UploaderComponent,
    UploadTaskComponent,
    DownloaderComponent,
    DownloadFileComponent
  ]
})
export class TransferModule { }
