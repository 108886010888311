<div class="card mt-2">
  <div class="card-header d-flex justify-content-between">
    <div class="d-flex">
        <i class="mr-4 h3 far" [ngClass]="type | async"></i>
        <h4><a href="{{upload.downloadURL}}" (click)="deleteUpload()" target="_blank" download="{{upload.filename}}">{{upload.filename}}</a></h4>
    </div>
    <!-- <p>{{type | async}}</p> -->
    <button class="btn btn-sm btn-danger" (click)="deleteUpload()">&times;</button>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-sm-4">
        <span class="font-weight-bolder">Upload Date:</span> {{upload.uploadDate.toDate() | date:"dd/MM/yyyy"}}
      </div>
      <div class="col-sm-4">
          <span class="font-weight-bolder">Expiry Date:</span> {{upload.expiryDate.toDate() | date:"dd/MM/yyyy"}}
      </div>
      <div class="col-sm-4">
        <span class="font-weight-bolder mr-2">Days Remaining:</span> {{daysRemaining | number: '1.0-0'}}
    </div>
    </div>
    <p></p>
  </div>
</div>