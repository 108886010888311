<header>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container">
      <div class="navbar-brand d-flex align-items-center">
        <h5>Send2Me <i class="fas fa-terminal ml-2"></i></h5>
      </div>
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a class="nav-link" routerLink="/transfer/upload" routerLinkActive="active">Upload</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/transfer/download" routerLinkActive="active">Download</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="logout()" href="#">Logout</a>
        </li>
      </ul>
    </div>
  </nav>
</header>
