<div class="card mt-2">
  <div class="card-body">
    <h3>{{file.name}}</h3>
    <p *ngIf="isUploading">Upload in progress</p>
    <div *ngIf="!isUploading && upload" >
      <p style = "font-weight: bold">Upload Complete</p>
    </div>
    <div *ngIf="!isUploading && !upload" >
      <p style="color:red">Upload Error</p>
    </div>
  </div>
</div>