import { Component, OnInit, Input } from '@angular/core';
import { FileTransferService } from 'src/app/services/file-transfer.service';
import { FileDataService } from 'src/app/services/file-data.service';
import { Upload } from '../../../models/upload';


@Component({
  selector: 'app-download-file',
  templateUrl: './download-file.component.html',
  styleUrls: ['./download-file.component.css']
})
export class DownloadFileComponent implements OnInit {
  @Input() upload: Upload;

  daysRemaining: number;
  type: Promise<string>;

  constructor(private fts: FileTransferService, private fds: FileDataService) {}

  ngOnInit() {

    const today = new Date();

    this.daysRemaining = (this.upload.expiryDate.toMillis() - today.getTime()) / (1000 * 3600 * 24);
    this.type = this.getFileTypeIcon(this.upload.type);
  }

  deleteUpload() {
    this.upload.delete = true;
    this.fds.deleteFile(this.upload);
  }

  async getFileTypeIcon(type: string = ''): Promise<string> {

    if (type.match(/sheet/)) {
      return 'fa-file-excel';
    } else if (type.match(/pdf/)) {
      return 'fa-file-pdf';
    } else if (type.match(/doc/)) {
      return 'fa-file-word';
    } else if (type.match(/word/)) {
      return 'fa-file-word';
    } else if (type.match(/audio\//)) {
      return 'fa-file-audio';
    } else if (type.match(/video\//)) {
      return 'fa-file-video';
    } else if (type.match(/image\//)) {
      return 'fa-file-image';
    } else {
      return 'fa-file';
    }
  }
}
