import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './auth/components/login/login.component';
import { redirectLoggedInTo, canActivate } from '@angular/fire/auth-guard';

const redirectLoggedInToDownload = () => redirectLoggedInTo(['transfer', 'download']);

const routes: Routes = [
    { path: 'login', component: LoginComponent, ...canActivate(redirectLoggedInToDownload) },
    { path: '', redirectTo: 'login', pathMatch: 'full' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
