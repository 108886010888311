import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AngularFireStorage, AngularFireUploadTask, AngularFireStorageReference } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class FileTransferService {

  constructor(private http: HttpClient, private storage: AngularFireStorage) { }

  uploadFile(file: File): Observable<firebase.storage.UploadTaskSnapshot> {
    let uploadTask: AngularFireUploadTask;
    const path = `upload/${Date.now()}_${file.name}`;
    const ref: AngularFireStorageReference = this.storage.ref(path);

    uploadTask = this.storage.upload(path, file);

    return uploadTask.snapshotChanges();
  }
}
