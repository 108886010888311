import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppMainComponent } from '../shared/components/app-main/app-main.component';
import { DownloaderComponent } from './components/downloader/downloader.component';
import { UploaderComponent } from './components/uploader/uploader.component';
import { redirectUnauthorizedTo, canActivate } from '@angular/fire/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);

const routes: Routes = [
    {
        path: 'transfer', component: AppMainComponent, ...canActivate(redirectUnauthorizedToLogin), children: [
            { path: 'download', component: DownloaderComponent },
            { path: 'upload', component: UploaderComponent },
            { path: '', redirectTo: 'download', pathMatch: 'full' }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class TransferRoutingModule { }
