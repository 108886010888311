<div
  class="dropzone d-flex flex-column my-3 align-items-center justify-content-center"
  appDropzone
  (hovered)="toggleHover($event)"
  (dropped)="onDrop($event)"
  [class.hovering]="isHovering"
>
  <div>
    <h3>File.io Dropzone</h3>
  </div>
  <div>
    <p>Drag files to upload here</p>
  </div>
</div>

<h3>Uploading</h3>

<div *ngFor="let file of files">
    <app-upload-task [file]="file" (completed)="onComplete(file)"></app-upload-task>
</div>




