<footer class="bg-light fixed-bottom">
  <div class="container">
  
    <div class="d-flex justify-content-center">
        <div>
            <p class="lead"> &copy; Robert Hendley {{year}}</p>
          </div>
    </div>
    
  </div>
  
</footer>


