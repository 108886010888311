import { Component, OnInit } from '@angular/core';
import { FileDataService } from '../../../services/file-data.service';
import { Observable } from 'rxjs';
import { Upload } from '../../../models/upload';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-downloader',
  templateUrl: './downloader.component.html',
  styleUrls: ['./downloader.component.css']
})
export class DownloaderComponent implements OnInit {

  uploads: Observable<Upload[]>;

  constructor(
    private fds: FileDataService,
    private title: Title
  ) { }

  ngOnInit() {
    this.title.setTitle('File Transfer | Download');
    this.uploads = this.fds.getFiles();
  }

}
