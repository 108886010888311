<div class="row section">
  <div class="col-sm-8 col-md-6 col-lg-5 mt-5 mx-auto">
    <div class="card mx-auto" id="login-card">
      <div class="card-body">
        <h1 class="h1 text-center">Login</h1>
        <form [formGroup]="loginForm" (ngSubmit)="doLogin()">
          <div class="form-group">
            <label class="label">Username</label>

            <input
              class="form-control"
              type="text"
              formControlName="username"
            />
          </div>
          <div class="field form-group">
            <label class="label">Password</label>

            <input
              class="form-control"
              type="password"
              formControlName="password"
            />
          </div>
          <button type="submit" class="btn btn-block btn-primary">Login</button>
        </form>
      </div>
    </div>
    <div *ngIf="loginError" class="alert alert-danger mt-2" role="alert">
      {{ loginText }}
    </div>
  </div>
</div>
