import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    loginError: boolean;
    loginText: string;
    loginForm: FormGroup;

    constructor(
        private router: Router,
        private authService: AuthService,
        private titleService: Title
    ) {
        this.loginForm = this.createLoginForm();
    }

    ngOnInit() {
        this.loginError = false;
        this.titleService.setTitle(this.titleService.getTitle() + ' | Login');
        // this.authService.getAuth().subscribe(userData => {
        //   if (userData) {
        //     console.log(`Already Logged in as ${userData.email}`);
        //     this.router.navigate(['/transfer/download']);
        //   }
        // });
    }

    createLoginForm() {
        return new FormGroup({
            username: new FormControl(),
            password: new FormControl()
        });
    }

    doLogin() {
        const username = this.loginForm.controls['username'].value;
        const password = this.loginForm.controls['password'].value;

        this.authService.login(username, password)
            .then(userData => {
                this.router.navigate(['/transfer/download']);
            })
            .catch(err => {
                this.loginError = true;
                this.loginText = err.message;

                setTimeout(() => {
                    this.loginError = false;
                }, 7500);
            });
    }

}
