import { NgModule } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { AuthService } from './auth.service';
import { FileTransferService } from './file-transfer.service';


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    AuthService,
    FileTransferService,
    Title
  ]
})
export class ServicesModule { }
