import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FileTransferService } from 'src/app/services/file-transfer.service';
import { FileDataService } from '../../../services/file-data.service';
import { finalize } from 'rxjs/operators';
import {
  AngularFireStorage,
  AngularFireUploadTask,
  AngularFireStorageReference
} from '@angular/fire/storage';
import { Upload } from '../../../models/upload';

@Component({
  selector: 'app-upload-task',
  templateUrl: './upload-task.component.html',
  styleUrls: ['./upload-task.component.css']
})
export class UploadTaskComponent implements OnInit {
  @Input() file: File;
  @Output() completed = new EventEmitter<boolean>(true);

  upload: Upload;
  isUploading: boolean;
  uploadDate: Date;
  uplaodTask: AngularFireUploadTask;
  downloadURL: string;

  constructor(
    private fts: FileTransferService,
    private storage: AngularFireStorage,
    private fds: FileDataService
  ) {}

  ngOnInit() {
    this.startFileUpload();
  }

  startFileUpload() {
    this.uploadDate = new Date();
    this.isUploading = true;
    const path = `upload/${Date.now()}_${this.file.name}`;

    const ref: AngularFireStorageReference = this.storage.ref(path);

    this.uplaodTask = this.storage.upload(path, this.file);

    this.uplaodTask
      .snapshotChanges()
      .pipe(
        finalize(() => {
          // Get the download URL
          console.log('finalised fired');
          ref.getDownloadURL().subscribe(URL => {
            console.log(URL);
            // Construct the Upload Object
            this.upload = {
              filename: this.file.name,
              downloadURL: URL,
              expiryDays: 7,
              delete: false,
              path: path,
              type: this.file.type
            };

             // Save the upload to firestore
            this.fds.addFile(this.upload);

            // Set the flags to indicate the upload is complete
            this.isUploading = false;
            this.completed.emit(true);
          });
        })
      ).subscribe();
  }
}
